import React, {FunctionComponent, InputHTMLAttributes, useState, useCallback, useEffect} from 'react';
import {useDropzone} from 'react-dropzone';

import {IFormFieldProps} from '../../IFormFieldProps';
import MyUploaderHeader from './MyUploaderHeader';
import Layout from './Layout';
import IconFactory from '../../svg/IconFactory';
import {useTranslation} from 'react-i18next';
import SendDocumentUseCase from '../../../../domain/Document/UseCase/SendDocumentUseCase';
import FetchDocumentGateway from '../../../../gateway/Document/FetchDocumentGateway';
import Document from '../../../../domain/Document/Document';
import Converter from '../../../util/Converter';
import LocalStorageGateway from '../../../../gateway/Form/LocalStorageGateway';
import Receipt from '../../../../domain/Receipt/Receipt';
import PreviewReceiptUseCase from '../../../../useCase/Receipt/PreviewReceiptUseCase';
import FetchReceiptDetailGateway from '../../../../gateway/Receipt/FetchReceiptDetailGateway';
import FieldErrorMessage from '../messages/FieldErrorMessage';
import RemoveReceiptUseCase from '../../../../useCase/Receipt/RemoveReceiptUseCase';
import LocalStorageReceiptGateway from '../../../../gateway/Receipt/LocalStorageReceiptGateway';
import Modal from "../../modal/Modal";
import useModal from "../../modal/useModal";
import envVariable from "../../../util/envVariable";
import Loader from "../../loader/Loader";

interface IProps extends InputHTMLAttributes<HTMLInputElement>, IFormFieldProps {
  classes?: string,
  label?: string,
  help?: string,
  icon?: {
    type?: string,
    label?: string
  },
  id: string
  blockId: string
  typeId: string
  receiptsDefault: Receipt[]|null
  presentationTmp?: string
  register: any
  clearErrors: any
  error: any
}

const MyUploader: FunctionComponent<IProps> = ({classes, label, help, icon, id, blockId, typeId, receiptsDefault, presentationTmp, register, clearErrors, error}) => {
  const {t} = useTranslation()

  const [isShowing, toggle] = useModal();
  const [contentModal, setContentModal] = useState<InputHTMLAttributes<string>>();
  const [modalSize, setModalSize] = useState<string>('modal');
  const [receipts, setReceipts] = useState<Receipt[]|null>(receiptsDefault)
  const [isLoading, setLoading] = useState<boolean>(false)
  const [showReceiptId, setShowReceiptId] = useState('')
  const [receiptName, setReceiptName] = useState('')

  const onDrop = useCallback(acceptedFiles => {
    clearErrors(id)

    const promises: Promise<any>[] = []
    acceptedFiles.map(file => {
      promises.push(new Converter().toBase64(file).then(base64 => {
        if (typeof base64 === 'string') {
          return new Document(typeId, id, base64, file.name)
        }
      }))
    })

    Promise.all(promises).then(documents => {
      if (documents.length > 0) {
        new SendDocumentUseCase(new FetchDocumentGateway(), new LocalStorageGateway()).execute(blockId, documents).then(response => {
          const newReceipts = response?.filter(receipt => receipt.elementId === id)
          setReceipts((newReceipts) ?? null)
        })
      }
    })

  }, [blockId, id, typeId])

  const {getRootProps, getInputProps, isDragActive} = useDropzone({
    onDrop
  })

  const removeFile = receiptToRemove => () => {
    new RemoveReceiptUseCase(new FetchReceiptDetailGateway(), new LocalStorageReceiptGateway(), new LocalStorageGateway()).execute(receiptToRemove).then(response => {
      if (response) {
        const newReceipts = receipts?.filter(receipt => receipt !== receiptToRemove)
        setReceipts((newReceipts) ?? null)
      }
    })
  }

  const show = receiptToShow => () => {
    new PreviewReceiptUseCase(new FetchReceiptDetailGateway()).execute(receiptToShow.receiptId).then(response => {
      downloadFile(response, response.resource)
    })
  }

  const downloadFile = (receipt, dataBase64) => {
    const blob = new Converter().dataURItoBlob(dataBase64)
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.href = url;
    a.download = receipt.title;
    a.click();
    window.URL.revokeObjectURL(url);
  }

  const previewReceipt = receipt => () => {
    setLoading(true)
    setShowReceiptId(receipt.receiptId)
    new PreviewReceiptUseCase(new FetchReceiptDetailGateway()).execute(receipt.receiptId).then(response => {
      if (response) {
        setLoading(false)
        setContentModal(buildModalContent(receipt, response))
        setReceiptName(response.title)
      }
    })
  }

  const buildModalContent = (receipt, response) => {
    const resource = response.resourceFile
    const resourceIsImage = blobIsImage(resource)
    const dataBase64 = response.resource
    resourceIsImage ? setModalSize('modal') : setModalSize('modal-lg')
    if (resource.includes('application')) {
      if (resource.includes('application/pdf')) {
        toggle()

        return (
            <div key={receipt.id} className="pj-modal-content-center">
              <iframe src={resource} width="100%" height="500"/>
            </div>
        )
      } else {
        downloadFile(receipt, dataBase64)
      }
    } else {
      toggle()
      switch (true) {
        case resource.includes('audio/'):
          return (
              <div key={receipt.id} className="pj-modal-content-center">
                <audio controls>
                  <source src={resource} type="audio/mp3"/>
                </audio>
              </div>
          )
        case resource.includes('video/'):
          return (
              <div key={receipt.id} className="pj-modal-content-center">
                <video controls width="640" height="360">
                  <source src={resource} type="video/mp4"/>
                </video>
              </div>
          )
        case resource.includes('image/'):
          return (
              <div key={receipt.id} className="pj-modal-content-center">
                <img src={resource} alt={receipt.title}/>
              </div>
          )
        case resource.includes('text/'):
          return (
              <div key={receipt.id} className="pj-modal-content-center">
                <iframe src={resource} width="100%" height="500"/>
              </div>
          )
      }
    }
  }

  const blobIsImage = (blobData: string) => {
    return blobData.includes('image/')
  }

  const isPreviewMode = () => {
    return envVariable('REACT_APP_PJ_PREVIEW_MODE')
  }

  const receiptElement = receipts?.filter((receipt) => {
    return receipt.elementId === id
  })

  return (
    <>
      {isShowing && isPreviewMode() &&
        <Modal title={t('pj.modal-preview-title')}
               description={receiptName}
               customClassName={modalSize}
               hide={toggle}>
          {contentModal}
        </Modal>
      }

      <MyUploaderHeader classes={classes}
                        label={label}
                        help={help} />
      <div {...getRootProps()} className={`dzu-dropzone ${isDragActive ? 'dzu-dropzoneActive' : ''}`}>
        <input {...getInputProps()} id={id + '-uploader'} />
        <input type={'hidden'} id={id} name={id} ref={register} data-skip-error={true} value={receiptElement && receiptElement?.length>0 ? "1" : ""}/> 

        <Layout icon={<IconFactory type={icon?.type} />}
                iconLabel={presentationTmp} />
      </div>

      {error?.message && <FieldErrorMessage message={error.message} />}

      {(receiptElement &&
        (receiptElement.map(receipt => (
            (id === receipt.elementId &&
              <div className="dzu-previewContainer" key={receipt.elementId}>
                {(isPreviewMode() && receipt?.resource &&
                  (
                    <div className="pj-container-min-preview pj-figure">
                      { (isLoading && showReceiptId === receipt.receiptId) && <Loader/> }
                      { (!isLoading || showReceiptId !== receipt.receiptId) &&
                        <div className="button align-items-end button--small button--width-fit button--height-fit pj-file-min-preview"
                             onClick={previewReceipt(receipt)}>
                          <IconFactory type={"eye"} />
                        </div>
                      }
                    </div>
                  )
                )}
                <div className="upload-progress__wrapper">
                  <div className="button align-items-end button-primary--outline button--small button--width-fit button--height-fit"
                       onClick={show(receipt)}>
                          {(isPreviewMode() && receipt?.resource &&
                            (
                              <IconFactory type={"download"} />
                            )
                          )}
                          &nbsp;
                          {receipt.title}
                  </div>
                  <div className="button button-error--outline button--small button--width-fit button--height-fit"
                       onClick={removeFile(receipt)}>{t('button.remove')}
                  </div>
                </div>
              </div>
            )
          ))
        )
      )}
    </>
  )
}

export default MyUploader;



