import React, {FunctionComponent} from 'react';

const FooterComponent: FunctionComponent = () => {

  return (
    <div className="main-footer">
      <ul className="main-footer__right">
      </ul>
    </div>
  );
}

export default FooterComponent;
