import FolderInterface from "./FolderInterface";
import Client from '../Client/Client';
import Receipt from '../Receipt/Receipt';

class Folder implements FolderInterface {
  id: string
  form_id: string
  client_id: number|null
  advistor_id: number
  last_submitted_block_id: string|null
  values: Array<string>
  direct: boolean
  receipts: Receipt[]|null
  authorizedClients: number[]
  status: Array<string>|undefined

  constructor(
    id: string,
    form_id: string,
    client_id: number|null,
    advistor_id: number,
    last_submitted_block_id: string|null,
    values: Array<string>,
    direct: boolean,
    receipts: Receipt[]|null,
    authorizedClients: number[],
    status?: Array<string>,
  ) {
    this.id = id
    this.form_id = form_id
    this.client_id = client_id
    this.advistor_id = advistor_id
    this.last_submitted_block_id = last_submitted_block_id
    this.values = values
    this.direct = direct
    this.receipts = receipts
    this.authorizedClients = authorizedClients
    this.status = status
  }
}

class FolderDetail implements FolderInterface{
  id: string
  creation_date: Date
  validation_data: Date
  signature_date: Date
  status: string
  client: Client
  detail: Array<string>
  advisor: { external_id: string, name: string }
  present: string

  constructor(
    id: string,
    creation_date: Date,
    validation_date: Date,
    signature_date: Date,
    status: string,
    client: Client,
    detail: Array<string>,
    advisor: { external_id: string, name: string },
    present: string,
  ) {
    this.id = id
    this.creation_date = creation_date
    this.validation_data = validation_date
    this.signature_date = signature_date
    this.status = status
    this.client = client
    this.detail = detail
    this.advisor = advisor
    this.present = present
  }
}
export {
  Folder,
  FolderDetail
}
