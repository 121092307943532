import React, {FunctionComponent} from 'react';

type Props = {
  color: string
}

const AbandonedFiles: FunctionComponent<Props> = ({color}) => {
  return (
    <svg width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M48.54 23.6259L46.3739 21.4599L34.9999 32.8339L23.6267 21.4599L21.4598 23.6259L32.8339 35L21.4598 46.374L23.6267 48.54L34.9999 37.166L46.3739 48.54L48.54 46.374L37.166 35L48.54 23.6259Z" fill={color}/>
      <path d="M35 68C53.2254 68 68 53.2254 68 35C68 16.7746 53.2254 2 35 2C16.7746 2 2 16.7746 2 35C2 53.2254 16.7746 68 35 68Z" stroke={color} strokeWidth="2.2" strokeMiterlimit="10"/>
    </svg>
  );
}

export default AbandonedFiles;
