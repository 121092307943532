import envVariable from "./envVariable";

export default class storage {
  static setFolderId(folder_id: string): boolean  {
    localStorage.setItem(envVariable('REACT_APP_FOLDER_ID_STORAGE'), folder_id)
    return true
  }

  static getFolderId(): string|null {
    return localStorage.getItem(envVariable('REACT_APP_FOLDER_ID_STORAGE'))
  }

  static setFormId(form_id: string): boolean {
    localStorage.setItem(envVariable('REACT_APP_FORM_ID'), form_id)
    return true
  }

  static getFormId(): string {
    return localStorage.getItem(envVariable('REACT_APP_FORM_ID')) ?? envVariable('REACT_APP_FORM_ID')
  }

  static getDossierState(): string {
    return localStorage.getItem(envVariable('REACT_APP_DOSSIER_STATE')) ?? ''
  }

  static setDossierState(state: string): boolean {
    localStorage.setItem(envVariable('REACT_APP_DOSSIER_STATE'), state)
    return true
  }

  static getClientId(): number|null {
    const clientId = Number(localStorage.getItem('client_id'))
    return (isNaN(clientId)) ? null : clientId
  }

  static setClientId(clientId: number): boolean {
    localStorage.setItem('client_id', String(clientId))
    return true
  }

  static getAuthorizedClients(): number[]|null {
    const authorizedClients = localStorage.getItem('authorized_clients')
    if (authorizedClients != null) {
      return JSON.parse(authorizedClients)
    }

    return null
  }

  static setAuthorizedClients(clientsId: number[]): boolean {
    localStorage.setItem('authorized_clients', JSON.stringify(clientsId))
    return true
  }

  static getReceipts(elementId: string): boolean {
    const receiptsString = localStorage.getItem('receipts')

    if (receiptsString != null) {
      const receipts = JSON.parse(receiptsString)

      const result = receipts.filter(receipt => receipt.elementId === elementId)
      return result.length > 0
    }

    return false
  }
}
