import FormGatewayInterface from './FormGatewayInterface';

export default class SaveUseCase
{
    public formGateway;

    constructor(FormGateway: FormGatewayInterface) {
        this.formGateway = FormGateway;
    }

    execute(data: Array<string>): Promise<unknown> {
        return this.formGateway.save(data)
    }
}
