import React, {FunctionComponent} from 'react';

import '../../../assets/styles/components/_loader.scss'

const Loader: FunctionComponent = () => {
  return (
    <div className="loader">Loading...</div>
  );
}

export default Loader;
