import React, {FunctionComponent} from 'react';

const Other: FunctionComponent = () => {

  return (
    <svg width="49" height="72" viewBox="0 0 49 72" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M40.7188 70.3721H8.71875C4.85276 70.3721 1.71875 67.2381 1.71875 63.3721V8.37207C1.71875 4.50608 4.85276 1.37207 8.71875 1.37207H36.1889C37.0523 1.37207 37.8738 1.74403 38.4434 2.39284L46.9732 12.1089C47.4538 12.6563 47.7188 13.3598 47.7188 14.0881V63.3721C47.7188 67.2381 44.5847 70.3721 40.7188 70.3721Z" fill="white"/>
      <path d="M40.7188 70.3721H8.71875C4.85276 70.3721 1.71875 67.2381 1.71875 63.3721V8.37207C1.71875 4.50608 4.85276 1.37207 8.71875 1.37207H36.1889C37.0523 1.37207 37.8738 1.74403 38.4434 2.39284L46.9732 12.1089C47.4538 12.6563 47.7188 13.3598 47.7188 14.0881V63.3721C47.7188 67.2381 44.5847 70.3721 40.7188 70.3721Z" fill="#119DA4" fillOpacity="0.1"/>
      <path d="M40.7188 70.3721H8.71875C4.85276 70.3721 1.71875 67.2381 1.71875 63.3721V8.37207C1.71875 4.50608 4.85276 1.37207 8.71875 1.37207H36.1889C37.0523 1.37207 37.8738 1.74403 38.4434 2.39284L46.9732 12.1089C47.4538 12.6563 47.7188 13.3598 47.7188 14.0881V63.3721C47.7188 67.2381 44.5847 70.3721 40.7188 70.3721Z" stroke="#119DA4" strokeWidth="2"/>
      <rect x="11.7188" y="54.3721" width="26" height="2" rx="1" fill="#119DA4"/>
      <rect x="11.7188" y="46.3721" width="26" height="2" rx="1" fill="#119DA4"/>
      <rect x="11.7188" y="38.3721" width="26" height="2" rx="1" fill="#119DA4"/>
      <rect x="11.7188" y="30.3721" width="26" height="2" rx="1" fill="#119DA4"/>
      <path d="M35.5001 5.66802C35.5 4.75398 36.6252 4.31899 37.24 4.99534L43.9792 12.4086C44.5632 13.051 44.1074 14.0813 43.2393 14.0813H36.5002C35.9479 14.0813 35.5002 13.6336 35.5002 13.0813L35.5001 5.66802Z" fill="#119DA4"/>
    </svg>
  );
}

export default Other;
