import React, {ReactNode, ReactPortal} from "react";
import ReactDOM from "react-dom";
import PageLoader from "../pageLoader/PageLoader";

interface IModal {
    hide: () => void ;
    title: string;
    description: string;
    children?: ReactNode;
    customClassName?: string;
    loading?: boolean
}

const Modal = ({ hide, title, description, children, customClassName, loading } :IModal) :ReactPortal=>
    ReactDOM.createPortal(
        loading ? <PageLoader/> :
        <>
            <div className="modal-overlay" onClick={hide}>
                <div className="modal-wrapper">
                    <div className={customClassName ?? "modal"}>
                        <div className="modal-header">
                            <h4>{title}</h4>
                            <button
                                type="button"
                                className="modal-close-button"
                                onClick={hide}
                            >
                                <span>&times;</span>
                            </button>
                        </div>
                        <div className="modal-description u-mbb">
                            {description}
                        </div>
                        <div className="modal-body">
                            {children}
                        </div>
                    </div>
                </div>
            </div>
        </>,
        document.body
    );

export default Modal;
