import React, {FunctionComponent} from 'react';

type Props = {
  color: string
}

const CurrentCases: FunctionComponent<Props> = ({color}) => {
  return (
    <svg width="66" height="66" viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M33 0C32.2903 0 31.5097 -6.87376e-08 30.8 0.0709677C30.2323 0.141935 29.7355 0.638709 29.8064 1.20645C29.8774 1.77419 30.3032 2.2 30.871 2.2H30.9419C31.6516 2.12903 32.2903 2.12903 33 2.12903C50.0323 2.12903 63.871 15.9677 63.871 33C63.871 50.0323 50.0323 63.871 33 63.871C15.9677 63.871 2.12903 50.0323 2.12903 33C2.12903 32.2903 2.12903 31.6516 2.2 30.9419C2.27097 30.3742 1.77419 29.8774 1.20645 29.8064C0.638709 29.7355 0.141935 30.2323 0.0709677 30.8C-6.87376e-08 31.5097 0 32.2903 0 33C0 51.1677 14.8323 66 33 66C51.1677 66 66 51.1677 66 33C66 14.8323 51.1677 0 33 0Z" fill={color}/>
      <path d="M15.2581 7.52256C15.471 7.52256 15.6839 7.45159 15.8258 7.30965C16.9613 6.52901 18.0968 5.8903 19.3032 5.32256C19.8 5.03868 20.0129 4.39997 19.8 3.9032C19.5161 3.40643 18.8774 3.19352 18.3807 3.40643C17.1032 4.04514 15.8258 4.75481 14.6903 5.53546C14.1936 5.8903 14.0516 6.52901 14.4065 7.02578C14.5484 7.38062 14.9032 7.52256 15.2581 7.52256Z" fill={color}/>
      <path d="M22.7097 3.83229C22.8516 3.83229 22.9226 3.83229 23.0645 3.76132C24.3419 3.33552 25.6194 2.98068 26.9677 2.69681C27.5355 2.55487 27.8903 1.98713 27.8194 1.41939C27.6774 0.851646 27.1097 0.496807 26.5419 0.567775C25.1226 0.851646 23.7032 1.20648 22.3548 1.70326C21.7871 1.91616 21.5032 2.4839 21.7161 3.05165C21.8581 3.54842 22.2839 3.83229 22.7097 3.83229Z" fill={color}/>
      <path d="M3.90323 19.8C4.04517 19.871 4.1871 19.9419 4.40001 19.9419C4.82581 19.9419 5.18065 19.729 5.32259 19.3742C5.89033 18.1678 6.60001 17.0323 7.30968 15.8968C7.66452 15.4 7.52259 14.7613 7.02581 14.4065C6.52904 14.0516 5.89033 14.1936 5.53549 14.6903C4.75484 15.8968 4.04517 17.1032 3.40646 18.4516C3.19355 18.9484 3.40646 19.5871 3.90323 19.8Z" fill={color}/>
      <path d="M1.49034 27.8193C1.56131 27.8193 1.63228 27.8193 1.70324 27.8193C2.20002 27.8193 2.62583 27.4645 2.76776 26.9677C3.05163 25.6903 3.40647 24.3419 3.83228 23.0645C4.04518 22.4967 3.69034 21.929 3.19357 21.7161C2.55486 21.5032 1.98712 21.787 1.77421 22.3548C1.27744 23.7032 0.922599 25.1225 0.638728 26.5419C0.567761 27.1096 0.922599 27.6774 1.49034 27.8193Z" fill={color}/>
      <path d="M9.01292 13.058C9.29679 13.058 9.58066 12.9161 9.79356 12.7032C10.6452 11.7096 11.6387 10.7161 12.6323 9.86446C13.0581 9.50962 13.129 8.79994 12.7032 8.37414C12.3484 7.94833 11.6387 7.87736 11.2129 8.30317C10.1484 9.22575 9.08389 10.2903 8.16131 11.3548C7.80647 11.7806 7.80647 12.4903 8.23227 12.8451C8.51614 12.9161 8.72905 13.058 9.01292 13.058Z" fill={color}/>
      <path d="M25.4774 50.6709C25.6903 50.9548 25.9742 51.0967 26.329 51.0967C26.6839 51.0967 26.9678 50.9548 27.1807 50.6709L50.2452 20.5096C50.6 20.0129 50.529 19.3742 50.0323 19.0193C49.5355 18.6645 48.8968 18.7355 48.5419 19.2322L26.329 48.329L15.6839 34.3484C15.329 33.8516 14.6903 33.7806 14.1936 34.1354C13.6968 34.4903 13.6258 35.129 13.9807 35.6258L25.4774 50.6709Z" fill={color}/>
    </svg>
  );
}

export default CurrentCases;
