import BlockInterface from './BlockInterface'
import ICondition from '../Condition/ICondition';

export default class Block implements BlockInterface {
  id: string
  page_id: string
  title: string
  rank: number
  help: string|null
  confirmation: string
  visible: boolean
  state: string
  condition: ICondition|null
  icon: string
  slug: string|null

  constructor(
    id: string,
    page_id: string,
    title: string,
    rank: number,
    help: string|null,
    confirmation: string,
    visible: boolean,
    condition: ICondition|null,
    icon: string,
    slug: string|null
  ) {
    this.id = id
    this.page_id = page_id
    this.title = title
    this.rank = rank
    this.help = help
    this.confirmation = confirmation
    this.visible = visible
    this.state = 'waiting'
    this.condition = condition
    this.icon = icon
    this.slug = slug
  }
}
