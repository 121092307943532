import React, {useState, useRef, FC, KeyboardEvent, ChangeEvent, InputHTMLAttributes, FormEvent, useEffect} from 'react'
import {IFormFieldProps} from "../../IFormFieldProps";
import Input from "./Input";
import parse from "html-react-parser";
import {useTranslation} from 'react-i18next';

interface IProps extends InputHTMLAttributes<HTMLInputElement>, IFormFieldProps, InputProps {
    id?: string
    classes: string,
    value?: string,
    defaultValue?: string
    onChange?: (e: FormEvent<HTMLInputElement>) => void,
    clearErrors: (id: string | undefined) => void
}

type InputProps = {
    isUppercase?: boolean
}

const IBANCodeInput: FC<IProps> = (
    {
        id,
        classes,
        label,
        onChange,
        value,
        defaultValue,
        register,
        clearErrors,
        error,
        required,
        isUppercase,
        ...rest
    }
) => {

    value = undefined !== value ? value : ''

    const [valCountry, setCountryCodeValue] = useState<string>(value.slice(0, 2))
    const [valIbanKey, setIbanKeyValue] = useState<string>(value.slice(2, 4))
    const [valBank, setBankCodeValue] = useState<string>(value.slice(4, 9))
    const [valCounter, setCounterCodeValue] = useState<string>(value.slice(9, 14))
    const [valAccount, setAccountNumberValue] = useState<string>(value.slice(14, 25))
    const [valRibKey, setRibKeyValue] = useState<string>(value.slice(25, 27))
    const [frInput, setFRInput] = useState<boolean>(true)

    const valCountryRef = useRef<HTMLInputElement>(null)
    const valIbanKeyRef = useRef<HTMLInputElement>(null)
    const valBankRef = useRef<HTMLInputElement>(null)
    const valCounterRef = useRef<HTMLInputElement>(null)
    const valAccountRef = useRef<HTMLInputElement>(null)
    const valRibKeyRef = useRef<HTMLInputElement>(null)
    const valBBankRef = useRef<HTMLInputElement>(null)

    const fullValue: string = valCountry + valIbanKey + valBank + valCounter + valAccount + valRibKey
    const BBankValue: string = valBank + valCounter + valAccount + valRibKey

    useEffect(() => {
        if ('' === value && defaultValue) {
            const v = defaultValue.replaceAll(' ', '');
            const countryCode = v.slice(0, 2)
            setCountryCodeValue(countryCode)
            setFRInput(countryCode == '' || countryCode?.toUpperCase() == 'F' || countryCode?.toUpperCase() == 'FR')
            setIbanKeyValue(v.slice(2, 4))
            setBankCodeValue(v.slice(4, 9))
            setCounterCodeValue(v.slice(9, 14))
            setAccountNumberValue(v.slice(14, 25))
            setRibKeyValue(v.slice(25, 27))
        }
    }, [value, defaultValue])

    const storeValue = (name: string, value: string, focus: boolean) => {
        switch (name) {
            case 'valCountry': {
                setCountryCodeValue(value)
                setFRInput(value == '' || value?.toUpperCase() == 'F' || value?.toUpperCase() == 'FR')
                if(focus) valIbanKeyRef.current?.focus()
                break
            }
            case 'valIbanKey': {
                setIbanKeyValue(value)
                if(focus) valBankRef.current?.focus()
                break
            }
            case 'valBank': {
                setBankCodeValue(value)
                if(focus) valCounterRef.current?.focus()
                break
            }
            case 'valCounter': {
                setCounterCodeValue(value)
                if(focus) valAccountRef.current?.focus()
                break
            }
            case 'valAccount': {
                setAccountNumberValue(value)
                if(focus) valRibKeyRef.current?.focus()
                break
            }
            case 'valRibKey': {
                setRibKeyValue(value)
                if(focus) valRibKeyRef.current?.focus()
                break
            }
            default:
                return
        }
    }

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const names = ['valCountry', 'valIbanKey','valBank', 'valCounter', 'valAccount', 'valRibKey'];
        const lens = [2, 2, 5, 5, 11, 2];
        const eltName = e.currentTarget.name == 'valBBank' ? 'valBank' : e.currentTarget.name;
        let idx = names.findIndex((element) => element == eltName)
        let v = e.currentTarget.value.replaceAll(' ', '')

        for(; idx < 6 ; idx++) {
            if(v.length < lens[idx]){
                storeValue(names[idx], v, false)
                if(!frInput) {
                    if(idx>=2 && e.currentTarget.name != 'valBBank')
                        valBBankRef.current?.focus()
                    for(idx++; idx<6 ; idx++)
                        storeValue(names[idx], '', false)
                }
                break
            }
            storeValue(names[idx], v.slice(0, lens[idx]), frInput || idx < 2)
            if((idx>=2 && e.currentTarget.name != 'valBBank'))
                valBBankRef.current?.focus()
            v = v.slice(lens[idx])
        }
    }

    const keyDownHandler = (e: KeyboardEvent) => {
        const target = e.target as HTMLInputElement
        if (e.key === 'Backspace') {
            switch (target.id) {
                case 'valIbanKey': {
                    if (!target.value.length) valCountryRef.current?.focus()
                    break
                }
                case 'valBank': {
                    if (!target.value.length) valIbanKeyRef.current?.focus()
                    break
                }
                case 'valCounter': {
                    if (!target.value.length) valBankRef.current?.focus()
                    break
                }
                case 'valAccount': {
                    if (!target.value.length) valCounterRef.current?.focus()
                    break
                }
                case 'valRibKey': {
                    if (!target.value.length) valAccountRef.current?.focus()
                    break
                }
                case 'valBBank': {
                    if (!target.value.length) valIbanKeyRef.current?.focus()
                    break
                }
                default:
                    return
            }
        }
    }
    const {t} = useTranslation()

    return (
        <div className={`${classes} ${rest.readonly && "readonly-element"}`}>
            <div>
                <div className={`iban-code-split`} title={rest.help}>
                    <div className={`iban-code-split-label`}>
                        <label className={``}>{parse(undefined !== label ? label : '')}</label>
                    </div>
                    <div className={`iban-code-split-content row`}>
                        <div className={`iban-code-split-content-portion iban-code-split-col-2`}>
                            <div className={`row`}>
                                <input
                                    className={`col-md-12`}
                                    ref={valCountryRef}
                                    value={!isUppercase ? valCountry : valCountry?.toUpperCase()}
                                    onChange={handleChange}
                                    onKeyDown={keyDownHandler}
                                    type="text"
                                    id="valCountry"
                                    name="valCountry"
                                    title={t('iban-code.country-code-help')}
                                    required={required}
                                />
                            </div>
                            <label className="row center-xs label-bottom" htmlFor="valCountry">
                                <div className={`col-md-12 label-text`}>{t('iban-code.country-code')}</div>
                            </label>
                        </div>
                        <div className={`iban-code-split-content-portion  iban-code-split-col-2`}>
                            <div className={`row`}>
                                <input
                                    className={`col-md-12`}
                                    ref={valIbanKeyRef}
                                    value={!isUppercase ? valIbanKey : valIbanKey?.toUpperCase()}
                                    onChange={handleChange}
                                    onKeyDown={keyDownHandler}
                                    type="text"
                                    id="valIbanKey"
                                    name="valIbanKey"
                                    title={t('iban-code.iban-key-help')}
                                    required={required}
                                />
                            </div>
                            <label className="row center-xs label-bottom" htmlFor="valIbanKey">
                                <div className={`col-md-12 label-text`}>{t('iban-code.iban-key')}</div>
                            </label>
                        </div>
                        <div className={frInput ? `iban-code-split-content-portion col-md-2` : `u-hide`}>
                            <div className={`row`}>
                                <input
                                    className={`col-md-12`}
                                    ref={valBankRef}
                                    value={!isUppercase ? valBank : valBank?.toUpperCase()}
                                    onChange={handleChange}
                                    onKeyDown={keyDownHandler}
                                    type="text"
                                    id="valBank"
                                    name="valBank"
                                    title={t('iban-code.bank-code-help')}
                                    required={required}
                                />
                            </div>
                            <label className="row center-xs label-bottom" htmlFor="valBank">
                                <div className={`col-md-12 label-text`}>{t('iban-code.bank-code')}</div>
                            </label>
                        </div>
                        <div className={frInput ? `iban-code-split-content-portion col-md-2` : `u-hide`}>
                            <div className={`row`}>
                                <input
                                    className={`col-md-12`}
                                    ref={valCounterRef}
                                    value={!isUppercase ? valCounter : valCounter?.toUpperCase()}
                                    onChange={handleChange}
                                    onKeyDown={keyDownHandler}
                                    type="text"
                                    id="valCounter"
                                    name="valCounter"
                                    title={t('iban-code.counter-code-help')}
                                    required={required}
                                />
                            </div>
                            <label className="row center-xs label-bottom" htmlFor="valLocal">
                                <div className={`col-md-12 label-text`}>{t('iban-code.counter-code')}</div>
                            </label>
                        </div>
                        <div className={frInput ? `iban-code-split-content-portion col-md-3` : `u-hide`}>
                            <div className={`row`}>
                                <input
                                    className={`col-md-12`}
                                    ref={valAccountRef}
                                    value={!isUppercase ? valAccount : valAccount?.toUpperCase()}
                                    onChange={handleChange}
                                    onKeyDown={keyDownHandler}
                                    type="text"
                                    id="valAccount"
                                    name="valAccount"
                                    title={t('iban-code.account-code-help')}
                                    required={required}
                                />
                            </div>
                            <label className="row center-xs label-bottom"  htmlFor="valLocal">
                                <div className={`col-md-12 label-text`}>{t('iban-code.account-code')}</div>
                            </label>
                        </div>
                        <div className={frInput ? `iban-code-split-content-portion  iban-code-split-col-2` : 'u-hide'}>
                            <div className={`row`}>
                                <input
                                    className={`col-md-12`}
                                    ref={valRibKeyRef}
                                    value={!isUppercase ? valRibKey : valRibKey?.toUpperCase()}
                                    onChange={handleChange}
                                    onKeyDown={keyDownHandler}
                                    type="text"
                                    id="valRibKey"
                                    name="valRibKey"
                                    title={t('iban-code.rib-key-help')}
                                    required={required}
                                />
                            </div>
                            <label className="row center-xs label-bottom" htmlFor="valRibKey">
                                <div className={`col-md-12 label-text`}>{t('iban-code.rib-key')}</div>
                            </label>
                        </div>
                        <div className={ frInput ? `u-hide` : `iban-code-split-content-portion col-xs-12 col-sm-9 col-md-9`}>
                            <div className={`row`}>
                                <input
                                    className={`col-md-12`}
                                    ref={valBBankRef}
                                    value={!isUppercase ? BBankValue : BBankValue?.toUpperCase()}
                                    onChange={handleChange}
                                    onKeyDown={keyDownHandler}
                                    type="text"
                                    id="valBBank"
                                    name="valBBank"
                                    title={t('iban-code.bbank-code-help')}
                                    required={required}
                                />
                            </div>
                            <label className="row center-xs label-bottom" htmlFor="valBBank">
                                <div className={`col-md-12 label-text`}>{t('iban-code.bbank-code')}</div>
                            </label>
                        </div>
                    </div>
                </div>
                <Input
                    classes={`absolute`}
                    register={register}
                    clearErrors={clearErrors}
                    type={'hidden'}
                    error={error}
                    id={id}
                    name={id}
                    required={required}
                    readonly={false}
                    value={fullValue}
                    defaultValue={defaultValue}
                    isUppercase={isUppercase}
                    data-skip-error={true}
                />
            </div>

        </div>
    )
}

export default IBANCodeInput
