import {FC} from "react";

import {EIconType} from "../../../domain/Element/Icon/EIconType";

import Eye from "../svg/Eye";
import Download from "./Download";
import Passport from './Passport';
import Electricity from './Electricity';
import Other from './Other';

interface IProps {
    type: EIconType|string|undefined
}

const IconFactory: FC<IProps> = ({type}) => {
    switch (type) {
        case EIconType.EYE:
            return <Eye color="var(--ink-1)" />
        case EIconType.DOWNLOAD:
            return <Download color="var(--ink-1)" />
        case EIconType.PASSPORT:
            return <Passport/>
        case EIconType.ELECTRICITY:
            return <Electricity/>
        case EIconType.OTHER:
            return <Other/>
        default:
            return <Other/>
    }
}

export default IconFactory
