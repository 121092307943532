import React, {FunctionComponent} from 'react';

import '../../../assets/styles/components/_page-loader.scss'
import {useTranslation} from "react-i18next";

const PageLoader: FunctionComponent = () => {
    const {t} = useTranslation()

    return (
        <>
            <div className="content-page-loader">
                <div className="page-loader"></div>
                <div className='loader-text'>{t('button.download-in-progress')}</div>
            </div>
            <div className="page-loader-backdrop fade show"></div>
        </>
    );
}

export default PageLoader;
