import FormGatewayInterface from '../../Form/FormGatewayInterface';

export default class GetValidationUseCase
{
  public formGateway

  constructor(FormGateway: FormGatewayInterface) {
    this.formGateway = FormGateway
  }

  execute(): Promise<[]|null> {
    return this.formGateway.getValidation()
  }
}
