import React, {FC, useEffect, useState} from 'react';
import {Route, RouteProps} from 'react-router-dom';
import Authentication from '../oauth/Authentication';

const PrivateRoute: FC<RouteProps> = ({...rest}) => {

  const [isAuthenticated, setAuthenticated] = useState<boolean>(false);

  useEffect(() => {
    if (!isAuthenticated) {
      Authentication.isLogged().then(isAuthenticated => {
        !isAuthenticated ?
          Authentication.authorize()
          : setAuthenticated(true);
      })
    }
  }, [isAuthenticated]);

  return (
    <Route {...rest}></Route>
  );
}

export default PrivateRoute;
