import React, {FunctionComponent, useEffect, useState} from 'react';
import {Popover} from 'react-tiny-popover';

import Button from '../button/Button';
import MarkRounded from '../svg/MarkRounded';
import GetValidationUseCase from '../../../domain/Validation/UseCase/GetValidationUseCase';
import FormGateway from '../../../gateway/Form/FormGateway';
import ValidationState from '../validation/ValidationState';

// Styles
import '../../../assets/styles/components/_validation.scss'
import {useTranslation} from 'react-i18next';

interface CustomComponentProps extends React.ComponentPropsWithoutRef<'div'> {
  onClick(): void;
}

const CustomComponent = React.forwardRef<HTMLDivElement, CustomComponentProps>((props, ref) => (
  <div ref={ref} onClick={props.onClick}>
    {props.children}
  </div>
));

const BlockValidationSummaryComponent: FunctionComponent = () => {
  const { t } = useTranslation()

  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false)

  const [validations, setValidations] = useState<any | null>(null)

  useEffect(() => {
    new GetValidationUseCase(new FormGateway()).execute().then(response => {
      setValidations(response)
    })
  }, [])


  return (
    <>
      {(validations !== null &&
        <div
          className={`box-elevations box-elevations--base box-elevations--mb u-mxAuto`}>
          <h2 className="help-wrapper u-mbs">
            {t('validation.state-of-validation')}
            <Popover
              isOpen={isPopoverOpen}
              positions={['bottom']}
              align={'end'}
              padding={10}
              onClickOutside={() => setIsPopoverOpen(false)}
              content={<div className="box-helpers">{t('validation.help-text')}</div>}>
              <CustomComponent onClick={() => setIsPopoverOpen(!isPopoverOpen)}>
                <Button classes="help__button" label="" position="alone" icon={<MarkRounded />} />
              </CustomComponent>
            </Popover>
          </h2>

          <div className="flex-container">
            <div className="col-md-12">
              <p className="u-txt-size-l u-txt-color-text-light  u-mbm">{t('validation.reminder-subscription-information')}</p>
            </div>
            <div className="col-md-12">
              <table className="table-validation">
                <thead>
                <tr>
                  <td className="u-txt-bold">{t('validation.category')}</td>
                  <td width="80px" align="center" className="u-txt-bold">{t('validation.state')}</td>
                  <td className="u-txt-bold">{t('validation.notes')}</td>
                </tr>
                </thead>
                <tbody>
                {(validations.map((validation) => {
                  return <tr>
                    <td>{validation[1].name}</td>
                    <td align={'center'}><ValidationState state={validation[1].state}/></td>
                    <td>{validation[1].notes}</td>
                  </tr>
                }))}
                </tbody>
              </table>
            </div>
          </div>
          <hr/>
          <h5>{t('validation.rcci')}</h5>
          <div className="flex-container end-lg">
            <Button classes="button button--large button-error--outline button--width-fit  u-txt-uppercase u-pxl u-mrm" label={t('validation.decline')} />
            <Button classes="button button--large button-success--outline button--width-fit u-txt-uppercase u-pxl" label={t('validation.accept')} />
          </div>
        </div>
      )}
    </>)
}

export default BlockValidationSummaryComponent
