import React, {FunctionComponent, useEffect, useState} from 'react'

import BlockState from './BlockState'
import ModifyComponent from './call-to-action/ModifyComponent'
import Block from '../../../domain/Block/Block'

import FormGateway from '../../../gateway/Form/FormGateway'
import FindUseCase from '../../../domain/Form/FindUseCase'
import FetchElementGateway from '../../../gateway/Element/FetchElementGateway'
import ElementsPreviewPresenter from '../../../domain/Element/ElementsPreviewPresenter'
import ElementPreview from '../../../domain/Element/ElementPreview'
import ValidationComponent from './call-to-action/ValidationComponent'

import parse from 'html-react-parser';
import envVariable from "../../util/envVariable";
import storage from '../../util/storage';

type Props = {
    pageId: string,
    block: Block,
    state: string,
    role: string
}

/**
 * Display in preview mode the last submitted bloc elements data
 * @param pageId
 * @param block
 * @param state
 * @param role
 * @constructor
 */
const BlockPreviewComponent: FunctionComponent<Props> = (
    {
        pageId,
        block,
        state,
        role
    }
) => {

    interface ICustomerElementIds {
        [key: string]: string | null
    }

    const stateClass = BlockState.getStateClass(state)
    const [formData, setFormData] = useState<ElementPreview[] | null>([])

    useEffect(
        () => {

            const productCodeElementIds: ICustomerElementIds = {
                voisin: '811c92ee-902c-4243-9129-8ef5c8f9a5dc',
                remake: '4beb8867-f580-428c-a7e9-7078a13a042b',
                altarea: '4beb8867-f580-428c-a7e9-7078a13a042b',
                sogenial: 'aa3b8725-f2a1-4e80-8d7a-d1987c8c992c',
                euryale: null,
                paref: 'f25cbf4a-d642-463b-ac40-3d590f817ede',
                'swisslife-am': '31d1a781-d961-4a20-b4e2-f3a462cffe21'
            }

            const customer = envVariable('REACT_APP_CUSTOMER')
            const productCodeElementId: string | null = productCodeElementIds[customer] ?? ''
            const productCode: string = (new FormGateway()).getValueForElementId(productCodeElementId.toString()) ?? ''

            if (state !== "disable") {
                const fetchElementGateway = new FetchElementGateway()
                fetchElementGateway.getElementsByBlockId(block.id, productCode, customer).then(
                    elements => {
                        const findUseCase = new FindUseCase(new FormGateway())
                        findUseCase.execute().then(
                            (data) => {
                                if (data !== null) {
                                    const formDataCopy = new ElementsPreviewPresenter().execute(elements, data)
                                    setFormData(formDataCopy)
                                }
                            }
                        )
                    }
                )
            }

        },
        [pageId, block.id, state]
    )

    /**
     * Guessing block animation css class by listening to block state change event through callback each 1ms timeout
     */
    let initialStateAnimationClass = 'slideDown'
    if (block.state === 'validate' || block.state === 'complete') {
        initialStateAnimationClass = 'slideUp'
    }
    const [stateAnimationClass, setStateAnimationClass] = useState<string>(initialStateAnimationClass)
    setTimeout(
        () => {
            if (block.state === 'validate' || block.state === 'complete') {
                setStateAnimationClass('slideDown')
            }
        },
        1
    )

    const dossierState = storage.getDossierState()
    /**
     * returning Block preview html presentation
     */
    return (
        <div className={`${stateAnimationClass}`}>
            <div className={`card ${stateClass} u-mxAuto`}>
                <div className="card__first">
                    <h3 className="card__title">{block.title}</h3>
                    <div className="card__content flex-container">
                        <p className="col-md-12">
                            {
                                formData != null
                                && formData.map(
                                    (data, index) => {
                                        return (
                                            data.presentation
                                            && <span key={index}>{parse(data.presentation)} <br/> </span>
                                        )
                                    }
                                )
                            }
                        </p>
                    </div>
                </div>
                {
                    role === 'bo' && <ValidationComponent blockId={block.id}/>
                }
                {
                    role !== 'bo' && (dossierState === 'ongoing' || dossierState === '') && <ModifyComponent blockId={block.id} pageId={pageId}/>
                }
            </div>
        </div>
    )
}

export default BlockPreviewComponent
