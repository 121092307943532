import React, {FunctionComponent} from 'react';

type Props = {
  color: string
};

const Download: FunctionComponent<Props> = ({color}) => {

    return (
        <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clipAboutYou)">
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M9.99967 6.83331H11.0597C11.653 6.83331 11.9463 7.55331 11.5263 7.97331L8.46634 11.0333C8.20634 11.2933 7.78634 11.2933 7.52634 11.0333L4.46634 7.97331C4.04634 7.55331 4.34634 6.83331 4.93967 6.83331H5.99967V3.49998C5.99967 3.13331 6.29967 2.83331 6.66634 2.83331H9.333C9.69967 2.83331 9.99967 3.13331 9.99967 3.49998V6.83331ZM3.99967 14.1666C3.63301 14.1666 3.33301 13.8666 3.33301 13.5C3.33301 13.1333 3.63301 12.8333 3.99967 12.8333H11.9997C12.3663 12.8333 12.6663 13.1333 12.6663 13.5C12.6663 13.8666 12.3663 14.1666 11.9997 14.1666H3.99967Z"
                      fill={color}/>
            </g>
            <defs>
                <clipPath id="clipAboutYou">
                    <rect width="16" height="16" fill="white" transform="translate(0 0.5)"/>
                </clipPath>
            </defs>
        </svg>
    );
}

export default Download;


