import React, {FunctionComponent, useEffect, useState} from "react";
import FolderComponent from "./FolderComponent";
import FetchFolderGateway from "../../../gateway/Folder/FetchFolderGateway";
import {FolderDetail} from "../../../domain/Folder/Folder";
import {EFolderStatus} from "../../../domain/Folder/EFolderStatus";

type Props = {
    term: string,
    state?: string
}

const FoldersComponent: FunctionComponent<Props> = ({term, state}) => {
    const [folders, setFolders] = useState<FolderDetail[] | null>(null);

    function handleDeleteFolder(id: string): void {
        if (!folders) return
        const newFolders = folders.filter((folder) => folder.id !== id)
        setFolders(newFolders)
    }

    useEffect(() => {
        (new FetchFolderGateway()).getFolders().then(
            folders => {
                if (null === folders) {
                    return;
                }
                const foldersFiltered = folders.filter(folder => (folder.present)?.toLowerCase().includes(term))
                switch (state) {
                    case 'signed-files':
                        setFolders(foldersFiltered.filter(folder => folder.status === EFolderStatus.Signed))
                        break
                    case 'signature-files':
                        // eslint-disable-next-line no-case-declarations
                        const signingSubscriptionStatuses = [
                            EFolderStatus.Mission_signed_by_subscriber,
                            EFolderStatus.Mission_signed_by_co_subscriber,
                            EFolderStatus.Mission_signed_by_subscriber_spouse,
                            EFolderStatus.Mission_signed_by_subscriber_advisor,
                            EFolderStatus.Subscription_signed_by_subscriber,
                            EFolderStatus.Subscription_signed_by_co_subscriber,
                            EFolderStatus.Subscription_signed_by_subscriber_spouse,
                            EFolderStatus.Subscription_signed_by_subscriber_advisor,
                            EFolderStatus.Signature,
                        ]
                        // eslint-disable-next-line no-case-declarations
                        const inSigningFolderStatus = (status) => signingSubscriptionStatuses.includes(status);

                        setFolders(
                            foldersFiltered.filter(
                                folder => folder.status.split('|').find(inSigningFolderStatus) !== undefined
                            )
                        )
                        break
                    case 'current-cases':
                        // eslint-disable-next-line no-case-declarations
                        const currentSubscriptionStatuses = [
                            EFolderStatus.Ongoing,
                            EFolderStatus.Mission_validated,
                            EFolderStatus.Mission_validation,
                            EFolderStatus.Mission_sent_to_subscriber,
                            EFolderStatus.Mission_sent_to_co_subscriber,
                            EFolderStatus.Mission_sent_to_subscriber_spouse,
                            EFolderStatus.Mission_sent_to_subscriber_advisor,
                            EFolderStatus.Mission_delivered_to_subscriber,
                            EFolderStatus.Mission_delivered_to_co_subscriber,
                            EFolderStatus.Mission_delivered_to_subscriber_spouse,
                            EFolderStatus.Mission_delivered_to_subscriber_advisor,
                            EFolderStatus.Mission_declined_by_subscriber,
                            EFolderStatus.Mission_declined_by_co_subscriber,
                            EFolderStatus.Mission_declined_by_subscriber_spouse,
                            EFolderStatus.Mission_declined_by_advisor,
                            EFolderStatus.Adequacy_report_validation,
                            EFolderStatus.Adequacy_report_validated,
                            EFolderStatus.Subscription_sent_to_subscriber,
                            EFolderStatus.Subscription_sent_to_co_subscriber,
                            EFolderStatus.Subscription_sent_to_subscriber_spouse,
                            EFolderStatus.Subscription_sent_to_subscriber_advisor,
                            EFolderStatus.Subscription_delivered_to_subscriber,
                            EFolderStatus.Subscription_delivered_to_co_subscriber,
                            EFolderStatus.Subscription_delivered_to_subscriber_spouse,
                            EFolderStatus.Subscription_delivered_to_subscriber_advisor,
                            EFolderStatus.Subscription_declined_by_subscriber,
                            EFolderStatus.Subscription_declined_by_co_subscriber,
                            EFolderStatus.Subscription_declined_by_subscriber_spouse,
                            EFolderStatus.Subscription_declined_by_advisor,
                        ]
                        // eslint-disable-next-line no-case-declarations
                        const isCurrentFolderStatus = (status) => currentSubscriptionStatuses.includes(status);
                        setFolders(
                            foldersFiltered.filter(
                                folder => folder.status.split('|').every(isCurrentFolderStatus)
                            )
                        )
                        break
                    case 'abandoned-files':
                        setFolders(foldersFiltered.filter(folder => (folder.status === 'canceled' || folder.status === 'rejected')))
                        break
                    default:
                        setFolders(foldersFiltered)
                }
            }
        )
    }, [term, state])

    return (
        <div className="flex-container">
            <div className="col-md-12">
                {folders?.map((FolderDetail) => {
                    return <FolderComponent FolderDetail={FolderDetail} key={FolderDetail.id}
                                            handleDeleteFolder={handleDeleteFolder}/>
                })}
            </div>
        </div>
    )
}

export default FoldersComponent;
