import {FC, InputHTMLAttributes} from "react"
import {IOptionType} from "../select/IOptionType";
import ERadioType from "./ERadioType";
import {useAppDispatch, useAppSelector} from '../../../../store/hook';
import Element from '../../../../domain/Element/Element';
import SaveUseCase from '../../../../domain/Form/SaveUseCase';
import FormGateway from '../../../../gateway/Form/FormGateway';
import getElementsIdToRender from '../../../util/getElementsIdToRender';
import {updateElementTimestamp} from '../../../../store/element/elements';

interface IProps extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
    option: IOptionType,
    checked: boolean,
    onChange: (value: string) => void,
    type: ERadioType,
    register?: () => void,
    name: string,
    disabled?: boolean
}

const RadioItem: FC<IProps> = ({option, onChange, type, register, name, disabled, checked}) => {
    const dispatch = useAppDispatch()

    const elementsWithConditionJSON = useAppSelector(state => state.elements.currentElementsWithCondition)
    const elementsWithCondition: Element[] = JSON.parse(elementsWithConditionJSON)
    const elementsWithCalculeJSON = useAppSelector(state => state.elements.currentElementsWithCalcule)
    const elementsWithCalcule: Element[] = JSON.parse(elementsWithCalculeJSON)
    const elementsWithReferenceJSON = useAppSelector(state => state.elements.currentElementsWithReference)
    const elementsWithReference: Element[] = JSON.parse(elementsWithReferenceJSON)

    function handleChange(event, value) {
        event.preventDefault()
        event.stopPropagation()

        onChange(value)

        const data = [];
        data[name] = value;
        const saveUseCase = new SaveUseCase(new FormGateway())
        saveUseCase.execute(data).then(() => {
            const elementsIdToRender: string[] = getElementsIdToRender(elementsWithCondition, name, elementsWithCalcule, elementsWithReference)
            elementsIdToRender.map(elementIdToRender => dispatch(updateElementTimestamp({'id': elementIdToRender})))
        })
    }

    return (
        <div className={type == ERadioType.SIMPLE ? "col-md-4" : "col-md-6"}>
            <label htmlFor={option.value} className={type}>
                <input type="radio" readOnly checked={checked} ref={register} name={name} value={option.value}
                       disabled={disabled} id={option.value}/>
                <span onClick={(e) => handleChange(e, option.value)}>{option.label}</span>
            </label>
        </div>
    )

};
export default RadioItem;
