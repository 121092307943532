import React from 'react';

import Router from './route/Router';

import './assets/styles/main.scss'

const App: React.FC = () => {
  return <Router/>
};

export default App;
